const redirects = {
   'url1' : { landing_page : 'https://www.doktorn.com/sjukdomar/mollusker-ett-vanligt-och-ofarligt-hudbesv%c3%a4r-hos-barn/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "mollusker" },
   'url2' : { landing_page : 'https://www.nopoxivir.se', name: "nopoxivir" },
   'url3' : { landing_page : 'https://www.doktorn.com/artikel/s%c3%a5-njuter-du-s%c3%a4kert-av-v%c3%a5rsolen/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "njuter-solen" },
   'url4' : { landing_page : 'https://www.doktorn.com/sjukdomar/nya-behandlingar-ger-hopp-vid-sv%c3%a5r-atopisk-dermatit/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "atopisk-dermatit" },
   'url5' : { landing_page : 'https://www.doktorn.com/artikel/hudcancer-%C3%B6kar-i-s%C3%B6dra-sverige/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "hudcancer-sodra-sverige" },
   'url6' : { landing_page : 'https://www.doktorn.com/artikel/experten-om-kim-kardashians-behandling-av-sin-psoriasis-det-finns-b%c3%a4ttre-alternativ/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "experten-om-kim" },
   'url7' : { landing_page : 'https://www.doktorn.com/sjukdomar/keratosis-pilaris-knottrig-hud-orsak-symtom-och-behandling/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "keratosis-pilaris" },
   'url8' : { landing_page : 'https://www.doktorn.com/nyhetsbrev/?utm_source=Nyhetsbrev&utm_medium=Reaktion&utm_campaign=Stand_alone', name: "keratosis-pilaris" },

   
   'url10' : { landing_page : 'http://www.pascoflair.se/', name: "pascoflair" },
   'url11' : { landing_page : 'https://www.doktorn.com/artikel/10-r%c3%a5d-f%c3%b6r-en-god-s%c3%b6mn/', name: "10-råd-för-en-god-sömn" },
   'url12' : { landing_page : 'https://www.doktorn.com/artikel/hj%c3%a4rnan-beh%c3%b6ver-god-s%c3%b6mn-och-fysisk-aktivitet-f%c3%b6r-bibeh%c3%a5llen-h%c3%a4lsa/', name: "hjärnan-behöver-god-sömn-och-fysisk-aktivitet-för-bibehållen-hälsa" },
   'url13' : { landing_page : 'https://www.doktorn.com/sjukdomar/nu-kan-du-sluta-snarka/', name: "nu-kan-du-sluta-snarka" },
   'url14' : { landing_page : 'https://www.doktorn.com/sjukdomar/s%c3%b6mnapn%c3%a9-dold-folksjukdom/', name: "sömnapné-dold-folksjukdom" },
   'url15' : { landing_page : 'https://www.doktorn.com/artikel/b%c3%a4ttre-s%c3%b6mn-michael-mosley-ger-sina-b%c3%a4sta-tips/', name: "bättre-sömn-michael-mosley-ger-sina-bästa-tips/" },
   'url16' : { landing_page : 'https://www.doktorn.com/sjukdomar/s%c3%b6mnproblem-vanligaste-orsakerna/', name: "sömnproblem-vanligaste-orsakerna" },
   'url17' : { landing_page : 'https://www.doktorn.com/artikel/6-strategier-f%c3%b6r-att-hantera-stress-effektivare/', name: "hantera-stress-effektivare" },
   
   'url18' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/swisha-en-gava/4/?utm_source=doktorn.com&utm_medium=standalone&utm_campaign=varkampanj&utm_content=artikel_elsa', name: "elsas-hjarta-stannade" },
   'url19' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/manadsgivare/?utm_source=doktorn.com&utm_medium=banner-standalone&utm_campaign=varkampanj&utm_content=armband', name: "hlf" },
   'url20' : { landing_page : 'https://www.doktorn.com/artikel/fr%C3%A5n-topp-till-t%C3%A5-hj%C3%A4rtat', name: "från-topp-till-tå-hjärtat" },
   'url21' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/swisha-en-gava/5/?utm_source=doktorn.com&utm_medium=standalone&utm_campaign=varkampanj&utm_content=studie_scapis', name: "varldsunik-svensk-studie-hindra-hjartinfarkter" },
   'url22' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/swisha-en-gava/2/?utm_source=doktorn.com&utm_medium=banner-standalone&utm_campaign=varkampanj&utm_content=kristina_wilja', name: "hlf-2" },
   'url23' : { landing_page : 'https://www.doktorn.com/sjukdomar/f%C3%B6rmaksflimmer-det-%C3%A4r-viktigt-med-behandling/', name: "förmaksflimmer-det-är-viktigt-med-behandling" },
   'url24' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/swisha-en-gava/3/?utm_source=doktorn.com&utm_medium=standalone&utm_campaign=varkampanj&utm_content=artikel_lars_lundh', name: "billigt-lakemedel-kan-hjalpa-hjartsvikt" },
   'url25' : { landing_page : 'https://www.hjart-lungfonden.se/stod-oss/swisha-en-gava/1/?utm_source=doktorn.com&utm_medium=banner-standalone&utm_campaign=varkampanj&utm_content=mark', name: "hlf-3" },
   'url26' : { landing_page : 'https://www.doktorn.com/sjukdomar/kroniskt-obstruktiv-lungsjukdom-kol', name: "kroniskt-obstruktiv-lungsjukdom-kol" },
   'url27' : { landing_page : 'https://www.doktorn.com/', name: "hemsida" },

   'url28' : { landing_page : 'https://www.doktorn.com/artikel/kostbehandling-vid-ibs/', name: "kostbehandling-vid-ibs" },
   'url29' : { landing_page : 'https://alflorex.se/', name: "Alflorex" },
   'url30' : { landing_page : 'https://www.doktorn.com/sjukdomar/diarr%C3%A9-s%C3%A5-f%C3%B6rebygger-och-behandlar-du-akut-diarr%C3%A9/', name: "diarré-så-förebygger-och-behandlar-du-akut-diarré" },
   'url31' : { landing_page : 'https://smectago.se/', name: "SmectaGo" },
   'url32' : { landing_page : 'https://www.doktorn.com/sjukdomar/gaser-i-magen-ballongmage/', name: "gaser-i-magen-ballongmage" },
   'url33' : { landing_page : 'https://www.doktorn.com/sjukdomar/inflammerade-tarmfickor-divertikulit/', name: "inflammerade-tarmfickor-divertikulit" },
   'url34' : { landing_page : 'https://forlaxgo.se/', name: "Forlaxgo" },
   'url35' : { landing_page : 'https://www.doktorn.com/artikel/s%C3%A5-ser-du-skillnad-p%C3%A5-magsjuka-och-vinterkr%C3%A4ksjuka-calicivirus/', name: "så-ser-du-skillnad-på-magsjuka-och-vinterkräksjuka-calicivirus" },
   'url36' : { landing_page : 'https://www.doktorn.com/', name: "hemsida" },
};


export default redirects